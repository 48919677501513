exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-not-supported-not-supported-tsx": () => import("./../../../src/pages/NotSupported/NotSupported.tsx" /* webpackChunkName: "component---src-pages-not-supported-not-supported-tsx" */),
  "component---src-templates-private-page-template-tsx": () => import("./../../../src/templates/private-page-template.tsx" /* webpackChunkName: "component---src-templates-private-page-template-tsx" */),
  "component---src-templates-public-page-template-tsx": () => import("./../../../src/templates/public-page-template.tsx" /* webpackChunkName: "component---src-templates-public-page-template-tsx" */),
  "component---src-templates-services-auth-login-tsx": () => import("./../../../src/templates/services/auth/login.tsx" /* webpackChunkName: "component---src-templates-services-auth-login-tsx" */),
  "component---src-templates-services-auth-register-tsx": () => import("./../../../src/templates/services/auth/register.tsx" /* webpackChunkName: "component---src-templates-services-auth-register-tsx" */),
  "component---src-templates-services-events-event-detail-tsx": () => import("./../../../src/templates/services/events/event-detail.tsx" /* webpackChunkName: "component---src-templates-services-events-event-detail-tsx" */),
  "component---src-templates-services-events-event-overview-tsx": () => import("./../../../src/templates/services/events/event-overview.tsx" /* webpackChunkName: "component---src-templates-services-events-event-overview-tsx" */),
  "component---src-templates-services-media-center-audio-detail-tsx": () => import("./../../../src/templates/services/media-center/audio-detail.tsx" /* webpackChunkName: "component---src-templates-services-media-center-audio-detail-tsx" */),
  "component---src-templates-services-media-center-media-center-tsx": () => import("./../../../src/templates/services/media-center/media-center.tsx" /* webpackChunkName: "component---src-templates-services-media-center-media-center-tsx" */),
  "component---src-templates-services-media-center-video-detail-tsx": () => import("./../../../src/templates/services/media-center/video-detail.tsx" /* webpackChunkName: "component---src-templates-services-media-center-video-detail-tsx" */),
  "component---src-templates-services-myjmc-myjmc-preferences-tsx": () => import("./../../../src/templates/services/myjmc/myjmcPreferences.tsx" /* webpackChunkName: "component---src-templates-services-myjmc-myjmc-preferences-tsx" */),
  "component---src-templates-services-privacy-policy-privacy-policy-tsx": () => import("./../../../src/templates/services/privacy-policy/privacy-policy.tsx" /* webpackChunkName: "component---src-templates-services-privacy-policy-privacy-policy-tsx" */),
  "component---src-templates-services-services-tsx": () => import("./../../../src/templates/services/services.tsx" /* webpackChunkName: "component---src-templates-services-services-tsx" */)
}

